import React from "react";
import { Button } from "react-bootstrap";

import "./login.css";
import { useAuth0 } from "@auth0/auth0-react";

function Login() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    !isAuthenticated && (
      <Button onClick={loginWithRedirect} className="login-btn">
        Log in
      </Button>
    )
  );
}
export default Login;
