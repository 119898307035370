import React, { useEffect, useState, useRef } from "react";

import JSZip from "jszip";
import { Row, Col, Container, Form, Card, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Papa from "papaparse";
import DatePickerComponent from "./Components/DatePickerComponent";
import "react-datepicker/dist/react-datepicker.css";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "./kaufmanchart.css";
import TimedTableData from "./Components/TimedTableData";
import StatesNav from "./Components/StatesNav";
import moment from "moment-timezone";
import azureFunctionService from "./Services/azureFunctionService";
import { Download } from "react-bootstrap-icons";

import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import axios from "axios";
import { Buffer } from "buffer";

function KaufmanChart() {
  const [filteredBlobData, setFilteredBlobData] = useState({
    filteredBothComponents: [],
    filteredDates: [],
    filteredTimes: [],
  });
  const [filteredDataDates, setFilteredDataDates] = useState({
    hr: [],
    es: [],
    both: [],
    download: [],
  });
  const [convertedStates, setConvertedStates] = useState({
    criticalArray: [],
    criticalComponents: [],
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState({ keyWord: "" });
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [detaineeJmsData, setDetaineeJmsData] = useState(null);
  const searchInputRef = useRef(null);
  const location = useLocation();
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  //setting jms data
  useEffect(() => {
    if (location?.state?.detainee?.id && location?.state?.user?.admToken) {
      const payload = { detaineeId: location?.state?.detainee?.id };
      azureFunctionService
        .getDetaineesJmsData(payload, location?.state?.user?.admToken)
        .then((response) => {
          setDetaineeJmsData(response?.data[0]);
        })
        .catch((error) => {
          console.log("FAILED", error);
        });
    }
  }, [location?.state?.detainee?.id, location?.state?.user?.admToken]);

  // Setting CRITICAL STATES
  useEffect(() => {
    if (location.state.detainee.custodyStates) {
      const dataChecking = Object.entries(
        location.state.detainee.custodyStates
      );

      setConvertedStates((prevState) => {
        const copy = { ...prevState };
        copy.criticalArray = dataChecking;
        copy.criticalComponents = dataChecking.map(mappingStates);
        return copy;
      });
    }
  }, [location.state.detainee.custodyStates]);

  // Setting first load data
  useEffect(() => {
    async function fetchFirstLoad() {
      const payload = {
        queryKey: "blobDataInitial",
        custodyId: location.state.detainee.id,
      };
      await azureFunctionService
        .getCustodyFirstRender(payload, location.state.user.token)
        .then((response) => {
          setFilteredBlobData((prevState) => {
            const copy = { ...prevState };
            copy.filteredDates = response.data.parsedHours;
            copy.filteredTimes = response.data.parsedHours;
            return copy;
          });
          setStartDate(
            response.data.parsedHours[response.data.parsedHours.length - 1]
          );
          setEndDate(
            response.data.parsedHours[response.data.parsedHours.length - 1] +
              3600000
          );
        });
    }
    fetchFirstLoad();
  }, [location.state.detainee.id, location.state.user.token]);

  // Setting data based on chosen start/end date

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      let starting = startDate;
      if (typeof starting === "object") {
        const date = moment.tz(
          startDate,
          "ddd MMM DD YYYY HH:mm:ss",
          moment.tz.guess()
        );
        const unixTimestamp = date.utc().unix();
        starting = unixTimestamp * 1000;
      }
      async function fetchHourData() {
        const payload = {
          queryKey: "blobDataHour",
          custodyId: location.state.detainee.id,
          epochTime: starting,
        };
        const response = await azureFunctionService.getCustodyHourData(
          payload,
          location.state.user.token
        );
        const blob = new Blob([new Uint8Array(response.data)], {
          type: "application/zip",
        });
        const zip = await JSZip.loadAsync(blob);

        const csvFile = await zip
          .file(`${location.state.detainee.id}.csv`)
          .async("text");
        const csvData = Papa.parse(csvFile, {
          header: true,
          trimHeaders: true,
          trimRows: true,
        }).data;

        const result = csvData.reduce(
          (acc, item) => {
            if (item.measurement === "hr") {
              const { exertionValue, ...hrItem } = item;
              acc.hr.push(hrItem);
              acc.both.push(hrItem);
              acc.download.push(item);
              //-------------------------
            } else if (item.measurement === "es") {
              const { hrValue, ...esItem } = item;
              acc.es.push(esItem);
              acc.both.push(esItem);
              acc.download.push(item);
            }

            return acc;
          },
          { hr: [], es: [], both: [], download: [] }
        );

        const mergedData = result.hr.map((item1) => {
          const item2 = result.es.find(
            (item2) => item2.timestamp === item1.timestamp
          );
          return {
            ...item1,
            ...item2,
          };
        });

        setFilteredDataDates((prevState) => {
          const copy = { ...prevState };
          copy.es = result.es;
          copy.hr = result.hr;
          copy.both = mergedData;
          copy.download = result.download;

          return copy;
        });
      }
      fetchHourData();
    }
  }, [
    convertedStates.criticalArray,
    endDate,
    location.state.detainee.id,
    location.state.user.token,
    startDate,
  ]);

  const mappingStates = (state) => {
    return (
      <StatesNav
        key={`StateNav:${state[0]}`}
        state={state}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    );
  };

  useEffect(() => {
    if (search.keyWord !== "") {
      const searchedData =
        filteredDataDates.both &&
        filteredDataDates.both.filter((item) => {
          return (
            item.measurement === search.keyWord ||
            item.hrValue === search.keyWord ||
            item.exertionValue === search.keyWord
          );
        });

      setFilteredBlobData((prevState) => {
        const copy = { ...prevState };
        copy.filteredBothComponents = searchedData;
        return copy;
      });
      setCurrentPage(1);
    } else {
      setFilteredBlobData((prevState) => {
        const copy = { ...prevState };
        copy.filteredBothComponents = filteredDataDates.both;
        return copy;
      });
    }
  }, [filteredDataDates.both, search.keyWord]);

  const onReset = () => {
    setSearch({ keyWord: "" });
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }

    setCurrentPage(1);
  };

  const nav = useNavigate();

  const onGoBack = () => {
    nav(-1);
  };

  const onChangeSearchField = (event) => {
    const target = event.target;
    const newValue = target.value;

    setSearch((prevState) => {
      const copy = { ...prevState };
      copy.keyWord = newValue;
      return copy;
    });
  };

  const locationLength = location.state.detainee.locations.length;

  const mappingDetaineesHistory = (item, index) => {
    return <TimedTableData key={`TimedTableData:${index}`} item={item} />;
  };

  const handleStartDateChange = (date) => {
    const newEndDate = moment(date).add(60, "minutes");
    setStartDate(date);
    setEndDate(newEndDate.toDate());
  };

  const handleEndDateChange = (date) => {
    if (startDate) {
      const diffInMinutes = moment
        .duration(moment(date).diff(moment(startDate)))
        .asMinutes();
      if (Math.abs(diffInMinutes) <= 60) {
        setEndDate(date);
      } else {
        const newStartDate = moment(date).subtract(60, "minutes");
        setStartDate(newStartDate.toDate());
        setEndDate(date);
      }
    } else {
      setEndDate(date);
    }
  };

  const handleSwitchToggle = () => {
    setIsSwitchChecked(!isSwitchChecked);
  };
  console.log("DETAINEE", detaineeJmsData);
  const downloadExcelData = async (data, newData, newHeaders) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("CombinedData");
    const imageUrl =
      detaineeJmsData?.info?.imageUrl &&
      !detaineeJmsData.info.imageUrl.startsWith("http://")
        ? detaineeJmsData?.info?.imageUrl
        : "";
    console.log("URL", detaineeJmsData?.info?.imageUrl);
    // Adding newData to the worksheet
    const newHeaderRow = worksheet.addRow(
      detaineeJmsData?.info?.imageUrl === "" ||
        detaineeJmsData?.info?.imageUrl === undefined ||
        detaineeJmsData?.info?.imageUrl === null
        ? [...newHeaders]
        : ["", "", ...newHeaders]
    );
    newData.forEach((row) => {
      const newRow =
        detaineeJmsData?.info?.imageUrl === "" ||
        detaineeJmsData?.info?.imageUrl === undefined ||
        detaineeJmsData?.info?.imageUrl === null
          ? [...newHeaders.map((header) => row[header])]
          : ["", "", ...newHeaders.map((header) => row[header])];
      worksheet.addRow(newRow);
    });

    // Change color of newHeaderRow
    newHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" }, // This is yellow. Change it to your desired color.
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Adding an empty row
    if (
      detaineeJmsData?.info?.imageUrl !== "" &&
      detaineeJmsData?.info?.imageUrl !== undefined &&
      detaineeJmsData?.info?.imageUrl !== null
    ) {
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([]);
    }

    worksheet.addRow([]);

    // Adding data to the worksheet
    const dataHeaders = Object.keys(data[0]);
    const dataHeaderRow = worksheet.addRow(dataHeaders);
    data.forEach((row) => {
      const newRow = dataHeaders.map((header) => row[header]);
      worksheet.addRow(newRow);
    });

    // Change color of dataHeaderRow and add border
    dataHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    worksheet.getColumn(1).width = 25;
    if (
      detaineeJmsData?.info?.imageUrl === "" ||
      detaineeJmsData?.info?.imageUrl === undefined ||
      detaineeJmsData?.info?.imageUrl === null
    ) {
      worksheet.getColumn(2).width = 15;
    }
    worksheet.getColumn(3).width = 14;
    worksheet.getColumn(4).width = 14;
    worksheet.getColumn(5).width =
      detaineeJmsData?.info?.imageUrl === "" ||
      detaineeJmsData?.info?.imageUrl === undefined ||
      detaineeJmsData?.info?.imageUrl === null
        ? 10
        : 14;
    worksheet.getColumn(6).width = 14;
    worksheet.getColumn(7).width =
      detaineeJmsData?.info?.imageUrl === "" ||
      detaineeJmsData?.info?.imageUrl === undefined ||
      detaineeJmsData?.info?.imageUrl === null
        ? 22
        : 8;
    worksheet.getColumn(8).width = 14;
    worksheet.getColumn(9).width = 22;
    // Assuming the imageUrl exists

    if (imageUrl !== "") {
      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });
      const imageData = Buffer.from(response.data, "binary");

      const imageId = workbook.addImage({
        buffer: imageData,
        extension: imageUrl.split(".").pop(),
      });

      // You can specify the placement of the image as per your needs
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 0 }, // Top-left corner of the image
        br: { col: 2, row: 10 }, // Bottom-right corner of the image
        editAs: "oneCell", // Image will be stretched to fit within the specified coordinates
      }); // assuming that the image will be placed in cell I2
    }
    // Fetch the image from the URL

    const localTime = moment(startDate).tz(moment.tz.guess());
    let formattedLocalTime = localTime.format("MMMM Do YYYY, h:mm:ss a");
    if (typeof startDate === "object") {
      const theTime = moment.tz(
        startDate,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ",
        moment.tz.guess()
      );
      formattedLocalTime = theTime.format("MMMM Do YYYY, h:mm:ss a");
    }

    const fileName = `${
      location.state.detainee.locations[
        location.state.detainee.locations.length - 1
      ].description
    } ${formattedLocalTime}.xlsx`;

    const buf = await workbook.xlsx.writeBuffer();

    // Using FileSaver to download the file
    saveAs(new Blob([buf]), fileName);
  };

  const handleDownloadClick = async () => {
    const data = filteredDataDates?.download;
    const newData = [
      {
        JmsId: detaineeJmsData?.info?.jmsId
          ? detaineeJmsData?.info?.jmsId
          : "---",
        BookingId: detaineeJmsData?.info?.bookingId
          ? detaineeJmsData?.info?.bookingId
          : "---",
        FirstName: detaineeJmsData?.info?.firstName
          ? detaineeJmsData?.info?.firstName
          : "---",
        LastName: detaineeJmsData?.info?.lastName
          ? detaineeJmsData?.info?.lastName
          : "---",
        Gender: detaineeJmsData?.info?.gender
          ? detaineeJmsData?.info?.gender
          : "---",
        DateOfBirth: detaineeJmsData?.info?.dateOfBirth
          ? detaineeJmsData?.info?.dateOfBirth
          : "---",
        HousingUnit: detaineeJmsData?.info?.housingUnit
          ? detaineeJmsData?.info?.housingUnit
          : "---",
      },
    ];
    const newHeaders = [
      "JmsId",
      "BookingId",
      "FirstName",
      "LastName",
      "Gender",
      "DateOfBirth",
      "HousingUnit",
    ];
    await downloadExcelData(data, newData, newHeaders);
  };

  const customLegend = [
    {
      value: "Critical",
      type: "circle",
      color: "red",
    },
  ];

  const seriesLegend = [
    {
      value: "Heart Rate",
      type: "line",
      color: "#FAA31D",
    },
  ];
  const barLegend = [
    {
      value: "Activity Level",
      type: "rect",
      color: "#413ea0",
    },
  ];
  const customLegendBandUnlocked = [
    {
      value: "Warning",
      type: "circle",
      color: "#f0750a",
    },
  ];

  const legendPayload = [
    ...seriesLegend,
    ...barLegend,
    ...customLegend,
    ...customLegendBandUnlocked,
  ];

  return (
    <React.Fragment>
      <Container fluid style={{ display: "flex", flexWrap: "wrap" }}>
        {convertedStates.criticalArray.length !== 0 ? (
          <Col xs={2} md={2} lg={2} className="my-3  rounded">
            <Row className="states-overflow mx-3 border border-white rounded">
              {convertedStates.criticalComponents}
            </Row>
          </Col>
        ) : (
          <></>
        )}

        <Col
          xs={10}
          md={10}
          lg={convertedStates.criticalArray.length !== 0 ? 10 : 12}
          className="my-3"
        >
          <Row>
            <Col xs={1} className="mx-2">
              <button
                style={{ marginTop: "32.5px" }}
                className="btn btn-outline-secondary mb-3 reset-btn "
                type="button"
                onClick={onGoBack}
              >
                Go Back
              </button>
            </Col>
            <Col xs={3}>
              <Form.Label>
                <strong>Start Date:</strong>
              </Form.Label>
              <DatePickerComponent
                selectedDate={startDate}
                handleDateChange={(date) => handleStartDateChange(date)}
                filteredTimes={filteredBlobData.filteredTimes}
                filteredDates={filteredBlobData.filteredDates}
                choosingDisabled={false}
                noTypingAllowed={true}
              />
            </Col>
            <Col xs={3}>
              <Form.Label>
                {" "}
                <strong>End Date:</strong>
              </Form.Label>
              <DatePickerComponent
                selectedDate={endDate}
                handleDateChange={(date) => handleEndDateChange(date)}
                choosingDisabled={true}
              />
            </Col>
            <Col xs={2} className="">
              <Form style={{ marginTop: "37.5px" }}>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={isSwitchChecked ? "Hide Table" : "Show Table"}
                  checked={isSwitchChecked}
                  onChange={handleSwitchToggle}
                />
              </Form>
            </Col>
            <Col xs={2} className="text-end">
              <Download
                size={30}
                style={{ marginTop: "35px" }}
                className="download-pointer"
                onClick={handleDownloadClick}
              />
            </Col>
          </Row>
          <Row style={{ height: 25 }}>
            <Col style={{ display: "flex", marginLeft: 70 }}>
              <strong className="mx-5">
                {" "}
                <p style={{ flex: 0.1 }}>
                  hrInactiveLower:{" "}
                  <span style={{ color: "#1025AB" }}>
                    {location.state.detainee.hrInactiveLower}
                  </span>
                </p>
              </strong>
              <strong className="mx-5">
                <p style={{ flex: 0.1 }}>
                  hrInactiveUpper:{" "}
                  <span style={{ color: "#D31637" }}>
                    {location.state.detainee.hrInactiveUpper}
                  </span>
                </p>
              </strong>
              <strong className="mx-5">
                <p style={{ flex: 0.1 }}>
                  hrActiveLower:{" "}
                  <span style={{ color: "#1025AB" }}>
                    {location.state.detainee.hrActiveLower}
                  </span>
                </p>
              </strong>
              <strong className="mx-5">
                <p style={{ flex: 0.1 }}>
                  hrActiveUpper:{" "}
                  <span style={{ color: "#D31637" }}>
                    {location.state.detainee.hrActiveUpper}
                  </span>
                </p>
              </strong>
            </Col>
          </Row>
          <Row className="my-3">
            <Row>
              <Col lg={isSwitchChecked ? 8 : 12}>
                <Col>
                  <div
                    style={{
                      width: "100%",
                      height: 775,
                    }}
                  >
                    <ResponsiveContainer>
                      <ComposedChart data={filteredDataDates.both}>
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis
                          dataKey="timestamp"
                          angle={-10}
                          textAnchor="end"
                          fontSize={14}
                          tickFormatter={(tick) =>
                            moment
                              .tz(tick, "YYYY-MM-DD HH:mm:ss Z", "UTC")
                              .local()
                              .format("hh:mm:ss A")
                          }
                        />
                        <YAxis
                          yAxisId="left"
                          orientation="left"
                          domain={[0, 200]}
                          tickCount={10}
                          label={{
                            value: "Heart Rate",
                            angle: -90,
                            position: "insideLeft",
                          }}
                        />
                        <YAxis
                          yAxisId="right"
                          orientation="right"
                          tickCount={10}
                          domain={[0, 2000]}
                          label={{
                            value: "Activity Level",
                            angle: -270,
                            position: "insideRight",
                          }}
                        />
                        <Tooltip
                          labelFormatter={(value) =>
                            moment
                              .tz(value, "YYYY-MM-DD HH:mm:ss Z", "UTC")
                              .local()
                              .format("YYYY-MM-DD hh:mm:ss A")
                          }
                        />
                        <Legend verticalAlign="top" payload={legendPayload} />

                        <Bar
                          isAnimationActive={false}
                          yAxisId="right"
                          dataKey="exertionValue"
                          fill="#413ea0"
                        />
                        <Line
                          yAxisId="left"
                          dot={(item, index) => {
                            const isMatching =
                              convertedStates.criticalArray.some(
                                (otherItem) => {
                                  const date = moment.tz(
                                    otherItem[1].createdAt,
                                    "UTC"
                                  );
                                  const formattedDate = date.format(
                                    "YYYY-MM-DD HH:mm:ssZ"
                                  );
                                  return (
                                    otherItem[1]?.title === "Critical" &&
                                    item.payload.timestamp === formattedDate
                                  );
                                }
                              );
                            const isMatchingBandUnlocked =
                              convertedStates.criticalArray.some(
                                (otherItem) => {
                                  const date = moment.tz(
                                    otherItem[1].createdAt,
                                    "UTC"
                                  );
                                  const formattedDate = date.format(
                                    "YYYY-MM-DD HH:mm:ssZ"
                                  );
                                  return (
                                    item.payload.timestamp === formattedDate &&
                                    otherItem[1]?.title === "Warning" &&
                                    (otherItem[1]?.message ===
                                      "Band Unlocked" ||
                                      otherItem[1]?.message === "Motion Alert")
                                  );
                                }
                              );
                            if (isMatchingBandUnlocked) {
                              return (
                                <>
                                  <circle
                                    key={`dot-${index}:${item.payload.timestamp}`}
                                    cx={item.cx}
                                    cy={item.cy}
                                    r={4}
                                    fill="#f0750a"
                                  />
                                </>
                              );
                            } else if (isMatching) {
                              return (
                                <>
                                  <circle
                                    key={`dot-${index}:${item.payload.timestamp}`}
                                    cx={item.cx}
                                    cy={item.cy}
                                    r={4}
                                    fill="#FF0000"
                                  />
                                </>
                              );
                            } else {
                              return false;
                            }
                          }}
                          isAnimationActive={false}
                          dataKey="hrValue"
                          stroke="#FAA31D"
                          activeDot={{
                            r: 5,
                            style: {
                              fill: "#1890ff", // transparent blue
                              opacity: 0.7,
                            },
                          }}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <div
                    style={{
                      display: "block",
                      marginLeft: 63,
                      color: "#6A6A6A",
                    }}
                  >
                    Low: 0 - 200
                  </div>
                  <div
                    style={{
                      display: "block",
                      marginLeft: 63,
                      color: "#6A6A6A",
                    }}
                  >
                    Medium: 201-500
                  </div>
                  <div
                    style={{
                      display: "block",
                      marginLeft: 63,
                      color: "#6A6A6A",
                    }}
                  >
                    High: 501+
                  </div>
                </Col>
              </Col>
              {isSwitchChecked ? (
                <Col lg={4}>
                  <Col sm={10} md={10} lg={10} className="w-100">
                    <Card>
                      <Card.Header className="cardheaderbg">
                        <Row className="my-1">
                          <Col>
                            <h2 className="mb-0 text-light">
                              {
                                location.state.detainee.locations[
                                  locationLength - 1
                                ].description
                              }
                            </h2>
                          </Col>
                          <Col sm={4} md={4} lg={6}>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <button
                                  className="btn btn-outline-secondary mx-2 reset-btn"
                                  type="button"
                                  onClick={onReset}
                                >
                                  Reset
                                </button>
                              </div>
                              <input
                                type="text"
                                className="form-control rounded"
                                id="search"
                                value={search.keyWord}
                                onChange={onChangeSearchField}
                                name="search"
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="search-addon"
                                ref={searchInputRef}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <div className="table-responsive border-0 overflow-y-hidden">
                          <Table className="table-min-height" hover>
                            <thead className="lightheader-room">
                              <tr>
                                <th>
                                  <strong>Time</strong>
                                </th>
                                <th>
                                  <strong>Measurement</strong>
                                </th>
                                <th>
                                  <strong>Value</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredDataDates.both ? (
                                filteredBlobData.filteredBothComponents
                                  .slice(startIndex, endIndex)
                                  .map(mappingDetaineesHistory)
                              ) : (
                                <Row className="justify-content-center">
                                  <Col className=" text-center">
                                    <h3>No Data</h3>
                                  </Col>
                                </Row>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Card.Body>
                      <Row>
                        <Col className="text-center">
                          <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={
                              filteredBlobData.filteredBothComponents
                                ? filteredBlobData.filteredBothComponents.length
                                : 0
                            }
                            onChange={handlePageChange}
                            showLessItems
                            className="my-2"
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Row>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default KaufmanChart;
