import React, { useEffect, useRef, useState, useCallback } from "react";
import { Container, Row, Col, Card, Table, Form } from "react-bootstrap";
import DetaineeTableData from "./DetaineeTableData";
import "./kcdetainees.css";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import WelcomeMessage from "./WelcomeMessage";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import azureFunctionService from "../Services/azureFunctionService";
import { useLocation } from "react-router-dom";

function KCDetainees({ userMetaData, user }) {
  const [detainees, setDetainees] = useState({
    detaineeArray: [],
    detaineeComponents: [],
  });

  const [search, setSearch] = useState({
    keyWord: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const searchInputRef = useRef(null);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 25,
    totalCountResult: 1,
    savedTotalCountResult: 1,
  });
  const location = useLocation();

  const mappingDetaineeInfo = useCallback(
    (detainee) => {
      return (
        <DetaineeTableData
          detainee={detainee}
          user={userMetaData}
          userRoles={user.userroles}
          agency={location.state ? location.state.agency.id : null}
          key={`Detainee-${detainee.id}`}
        />
      );
    },
    [location.state, user.userroles, userMetaData]
  );
  // No Search Or Mix Search by Critical, Date, Keyword
  useEffect(() => {
    // -----Search by 3-----

    // Date, Critical, Search Key NOT WORKING
    if (
      startDate !== null &&
      endDate !== null &&
      search.keyWord !== "" &&
      isSwitchChecked === true
    ) {
      const start = moment(startDate).valueOf();
      let end = moment(endDate).valueOf();
      if (start === end) {
        end = moment(endDate).valueOf() + 90999000;
      }
      const payload = {
        queryKey: "criticalDateSearch",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        offset: (pagination.currentPage - 1) * pagination.itemsPerPage,
        startDate: start,
        endDate: end,
        searchKey: search.keyWord,
      };

      azureFunctionService
        .getAgencyCriticalDateSearch(payload, userMetaData?.token)
        .then((response) => {
          setDetainees((prevState) => {
            const copy = { ...prevState };
            copy.detaineeArray = response.data;
            copy.detaineeComponents = response.data.map(mappingDetaineeInfo);
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      const payloadCount = {
        queryKey: "countCriticalDateSearch",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        startDate: start,
        endDate: end,
        searchKey: search.keyWord,
      };

      azureFunctionService
        .getAgencyCriticalDateSearchCount(payloadCount, userMetaData?.token)
        .then((response) => {
          setPagination((prevState) => {
            const copy = { ...prevState };
            copy.totalCountResult = response.data[0];
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // -----Search by 2-----

    // Critical + Search Key
    else if (
      isSwitchChecked === true &&
      search.keyWord !== "" &&
      startDate === null &&
      endDate === null
    ) {
      const payload = {
        queryKey: "criticalSearch",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        offset: (pagination.currentPage - 1) * pagination.itemsPerPage,
        searchKey: search.keyWord,
      };

      azureFunctionService
        .getAgencyCriticalSearch(payload, userMetaData?.token)
        .then((response) => {
          setDetainees((prevState) => {
            const copy = { ...prevState };
            copy.detaineeArray = response.data;
            copy.detaineeComponents = response.data.map(mappingDetaineeInfo);
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      const payloadCount = {
        queryKey: "countCriticalSearch",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        searchKey: search.keyWord,
      };

      azureFunctionService
        .getAgencyCriticalSearchCount(payloadCount, userMetaData?.token)
        .then((response) => {
          setPagination((prevState) => {
            const copy = { ...prevState };
            copy.totalCountResult = response.data[0];
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // Critical & Date
    else if (
      isSwitchChecked === true &&
      startDate !== null &&
      endDate !== null &&
      search.keyWord === ""
    ) {
      const start = moment(startDate).valueOf();
      let end = moment(endDate).valueOf();
      if (start === end) {
        end = moment(endDate).valueOf() + 90999000;
      }
      const payload = {
        queryKey: "criticalDate",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        offset: (pagination.currentPage - 1) * pagination.itemsPerPage,
        startDate: start,
        endDate: end,
      };

      azureFunctionService
        .getAgencyCriticalDate(payload, userMetaData?.token)
        .then((response) => {
          setDetainees((prevState) => {
            const copy = { ...prevState };
            copy.detaineeArray = response.data;
            copy.detaineeComponents = response.data.map(mappingDetaineeInfo);
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      const payloadCount = {
        queryKey: "countCriticalDate",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        startDate: start,
        endDate: end,
      };

      azureFunctionService
        .getAgencyCriticalDateCount(payloadCount, userMetaData?.token)
        .then((response) => {
          setPagination((prevState) => {
            const copy = { ...prevState };
            copy.totalCountResult = response.data[0];
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // Date & Search Key
    else if (
      startDate !== null &&
      endDate !== null &&
      search.keyWord !== "" &&
      isSwitchChecked === false
    ) {
      const start = moment(startDate).valueOf();
      let end = moment(endDate).valueOf();
      if (start === end) {
        end = moment(endDate).valueOf() + 90999000;
      }
      const payload = {
        queryKey: "dateSearch",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        offset: (pagination.currentPage - 1) * pagination.itemsPerPage,
        startDate: start,
        endDate: end,
        searchKey: search.keyWord,
      };

      azureFunctionService
        .getAgencyDateSearch(payload, userMetaData?.token)
        .then((response) => {
          setDetainees((prevState) => {
            const copy = { ...prevState };
            copy.detaineeArray = response.data;
            copy.detaineeComponents = response.data.map(mappingDetaineeInfo);
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      const payloadCount = {
        queryKey: "countDateSearch",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        startDate: start,
        endDate: end,
        searchKey: search.keyWord,
      };

      azureFunctionService
        .getAgencyDateSearchCount(payloadCount, userMetaData?.token)
        .then((response) => {
          setPagination((prevState) => {
            const copy = { ...prevState };
            copy.totalCountResult = response.data[0];
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // -----Search by 1-----

    // Date
    else if (
      startDate !== null &&
      endDate !== null &&
      search.keyWord === "" &&
      isSwitchChecked === false
    ) {
      const start = moment(startDate).valueOf();
      let end = moment(endDate).valueOf();
      if (start === end) {
        end = moment(endDate).valueOf() + 90999000;
      }
      const payload = {
        queryKey: "date",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        offset: (pagination.currentPage - 1) * pagination.itemsPerPage,
        startDate: start,
        endDate: end,
      };

      azureFunctionService
        .getAgencyDate(payload, userMetaData?.token)
        .then((response) => {
          setDetainees((prevState) => {
            const copy = { ...prevState };
            copy.detaineeArray = response.data;
            copy.detaineeComponents = response.data.map(mappingDetaineeInfo);
            return copy;
          });
          setPagination((prevState) => {
            const copy = { ...prevState };
            copy.totalCountResult = response.data.length;
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      const payloadCount = {
        queryKey: "countDate",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        startDate: start,
        endDate: end,
      };

      azureFunctionService
        .getAgencyDateCount(payloadCount, userMetaData?.token)
        .then((response) => {
          setPagination((prevState) => {
            const copy = { ...prevState };
            copy.totalCountResult = response.data[0];
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // Search Key
    else if (
      search.keyWord !== "" &&
      isSwitchChecked === false &&
      startDate === null &&
      endDate === null
    ) {
      const payload = {
        queryKey: "search",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        offset: (pagination.currentPage - 1) * pagination.itemsPerPage,
        searchKey: search.keyWord,
      };

      azureFunctionService
        .getAgencySearch(payload, userMetaData?.token)
        .then((response) => {
          setDetainees((prevState) => {
            const copy = { ...prevState };
            copy.detaineeArray = response.data;
            copy.detaineeComponents = response.data.map(mappingDetaineeInfo);
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      const payloadCount = {
        queryKey: "countSearch",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        searchKey: search.keyWord,
      };

      azureFunctionService
        .getAgencySearchCount(payloadCount, userMetaData?.token)
        .then((response) => {
          setPagination((prevState) => {
            const copy = { ...prevState };
            copy.totalCountResult = response.data[0];
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // Critical
    else if (
      isSwitchChecked === true &&
      startDate === null &&
      endDate === null &&
      search.keyWord === ""
    ) {
      const payload = {
        queryKey: "critical",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
        offset: (pagination.currentPage - 1) * pagination.itemsPerPage,
      };

      azureFunctionService
        .getAgencyCritical(payload, userMetaData?.token)
        .then((response) => {
          setDetainees((prevState) => {
            const copy = { ...prevState };
            copy.detaineeArray = response.data;
            copy.detaineeComponents = response.data.map(mappingDetaineeInfo);
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      const payloadCount = {
        queryKey: "countCritical",
        agencyId: location.state
          ? location.state.agency.id
          : userMetaData?.agencyId,
      };

      azureFunctionService
        .getAgencyCriticalCount(payloadCount, userMetaData?.token)
        .then((response) => {
          setPagination((prevState) => {
            const copy = { ...prevState };
            copy.totalCountResult = response.data[0];
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //  no search, disabled critical, dates null
    else {
      if (
        isSwitchChecked === false &&
        startDate === null &&
        endDate === null &&
        search.keyWord === ""
      ) {
        const payload = {
          queryKey: "firstload",
          agencyId: location.state
            ? location.state.agency.id
            : userMetaData?.agencyId,
          offset: (pagination.currentPage - 1) * pagination.itemsPerPage,
        };

        azureFunctionService
          .getAgency(payload, userMetaData?.token)
          .then((response) => {
            setDetainees((prevState) => {
              const copy = { ...prevState };
              copy.detaineeArray = response.data;
              copy.detaineeComponents = response.data.map(mappingDetaineeInfo);
              return copy;
            });
          })
          .catch((error) => {
            console.log(error);
          });

        const payloadCount = {
          queryKey: "countFirstLoad",
          agencyId: location.state
            ? location.state.agency.id
            : userMetaData?.agencyId,
        };

        azureFunctionService
          .getAgencyCount(payloadCount, userMetaData?.token)
          .then((response) => {
            setPagination((prevState) => {
              const copy = { ...prevState };
              copy.totalCountResult = response.data[0];
              return copy;
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [
    endDate,
    isSwitchChecked,
    location.state,
    mappingDetaineeInfo,
    pagination.currentPage,
    pagination.itemsPerPage,
    search.keyWord,
    startDate,
    userMetaData?.agencyId,
    userMetaData?.token,
  ]);

  const onReset = () => {
    setSearch({ keyWord: "" });
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    setDetainees((prevState) => {
      const copy = { ...prevState };
      copy.detaineeComponents =
        detainees.detaineeArray.map(mappingDetaineeInfo);
      return copy;
    });
  };

  const onResetDate = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const onChangeSearchField = (event) => {
    const target = event.target;
    const newValue = target.value;
    const fieldName = target.name;

    setSearch((prevState) => {
      const copy = { ...prevState };
      copy.keyWord = newValue;
      return copy;
    });
    console.log({ fieldName, newValue });
  };
  const handleSwitchToggle = () => {
    setIsSwitchChecked(!isSwitchChecked);
  };

  const handlePageChange = (newPage) => {
    setPagination((prevState) => ({
      ...prevState,
      currentPage: newPage, // updates the currentPage state object with the new page - the page the user is navigating to
    }));
  };

  // Load the switch state from localStorage on component mount
  useEffect(() => {
    const isSwitchChecked = localStorage.getItem("isSwitchChecked");
    if (isSwitchChecked === "true") {
      setIsSwitchChecked(true);
    }
  }, []);

  // Save the switch state to localStorage when it changes
  useEffect(() => {
    localStorage.setItem("isSwitchChecked", isSwitchChecked);
  }, [isSwitchChecked]);

  return (
    <Container fluid className="my-3">
      {userMetaData !== undefined || null ? (
        <Row>
          <Col lg={12} md={12} sm={12} className="w-100">
            <Card>
              <Card.Header className="cardheaderbg">
                <Row className="my-1">
                  <Col md={3} lg={3}>
                    <h2 className="mb-0 text-light">Detainees</h2>
                  </Col>
                  <Col md={2} lg={2}>
                    <Form>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label={
                          isSwitchChecked
                            ? "Disable Critical Only"
                            : "Enable Critical Only"
                        }
                        checked={isSwitchChecked}
                        onChange={handleSwitchToggle}
                        className="text-white"
                      />
                    </Form>
                  </Col>
                  <Col md={4} lg={4}>
                    <div className="d-flex align-items-center">
                      <button
                        className="btn btn-outline-secondary mx-2 reset-btn"
                        type="button"
                        onClick={onResetDate}
                      >
                        X
                      </button>

                      <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        placeholderText="Click to enter date"
                        className="rounded w-50 flex-grow-1"
                      />
                    </div>
                  </Col>

                  <Col sm={3} md={3} lg={3}>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <button
                          className="btn btn-outline-secondary mx-2 reset-btn"
                          type="button"
                          onClick={onReset}
                        >
                          Reset
                        </button>
                      </div>
                      <input
                        type="text"
                        className="form-control rounded"
                        id="search"
                        value={search.keyWord}
                        onChange={onChangeSearchField}
                        name="search"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                        ref={searchInputRef}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="table-responsive border-0 overflow-y-hidden">
                  <Table hover>
                    <thead className="lightheader-room">
                      <tr>
                        <th>
                          <strong>Date</strong>
                        </th>
                        <th>
                          <strong>Name:</strong>
                        </th>
                        <th>
                          <strong>Critical Alert</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{detainees.detaineeComponents}</tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-center my-3">
                  <Pagination
                    current={pagination.currentPage}
                    pageSize={pagination.itemsPerPage}
                    total={pagination.totalCountResult}
                    onChange={handlePageChange}
                    showLessItems // reduces the amount of buttons being shown in pagination
                    className="my-2"
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <WelcomeMessage user={user} />
      )}
    </Container>
  );
}

export default KCDetainees;
