import "./App.css";
import { Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import NavigationBar from "./Components/NavigationBar";
import "bootstrap/dist/css/bootstrap.min.css";
import KCDetainees from "./Components/KCDetainees";
import KaufmanChart from "./KaufmanChart";
import { useAuth0 } from "@auth0/auth0-react";
import Landing from "./Components/Landing";
import Agencies from "./Components/Agencies";

function App() {
  const [userMetaData, setUserMetaData] = useState(null);

  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  useEffect(() => {
    const getUserMetaData = async () => {
      const domain = "4sightlabs.us.auth0.com";

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          },
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { app_metadata } = await metadataResponse.json();

        setUserMetaData(app_metadata);
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetaData();
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <React.Fragment>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Routes>
        {isAuthenticated && user.userroles.includes("4SightAdmin") ? (
          <React.Fragment>
            <Route
              path="/"
              element={
                <Landing isAuthenticated={isAuthenticated} user={user} />
              }
            />
            <Route
              path="/Agencies"
              element={<Agencies userMetaData={userMetaData} user={user} />}
            />
            <Route
              path="/Agencies/agency/:id"
              element={<KCDetainees userMetaData={userMetaData} user={user} />}
            />
            <Route
              path="/Agencies/agency/:id/detainee/:id"
              element={<KaufmanChart />}
            />
          </React.Fragment>
        ) : isAuthenticated ? (
          <React.Fragment>
            <Route
              path="/"
              element={
                <Landing isAuthenticated={isAuthenticated} user={user} />
              }
            />
            <Route
              path="/Detainees"
              element={<KCDetainees userMetaData={userMetaData} user={user} />}
            />
            <Route path="/Detainees/detainee/:id" element={<KaufmanChart />} />
          </React.Fragment>
        ) : (
          <Route
            path="/"
            element={<Landing isAuthenticated={isAuthenticated} user={user} />}
          />
        )}
      </Routes>
    </React.Fragment>
  );
}

export default App;
