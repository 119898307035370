import React, { useState, useEffect } from "react";
import "./detaineetabledata.css";
import { useNavigate } from "react-router";
import { Badge } from "react-bootstrap";
import moment from "moment";

function DetaineeTableData({ detainee, user, userRoles, agency }) {
  const [critical, setCritical] = useState({ states: [] });
  const [dataInfo, setDataInfo] = useState({ detainee: [], user: [] });

  const navigate = useNavigate();

  const locationLength = detainee.locations.length - 1;
  const description = detainee.locations[locationLength].description;

  const convertDate = moment.utc(detainee.createdAt);
  const localDate = convertDate.local();
  const localDateString = localDate.format("YYYY-MM-DD h:mm:ss A");
  useEffect(() => {
    setDataInfo((prevState) => {
      const copy = { ...prevState };
      copy.detainee = detainee;
      copy.user = user;
      return copy;
    });
  }, [detainee, user]);

  useEffect(() => {
    if (detainee.custodyStates) {
      const dataChecking = Object.entries(detainee.custodyStates);

      const filterStates =
        dataChecking &&
        dataChecking.filter((item) => {
          return (
            item[1].message === "Abnormal heart rate" ||
            item[1].message === "Abnormal Motion" ||
            item[1].title === "Critical"
          );
        });
      setCritical((prevState) => {
        const copy = { ...prevState };
        copy.states = filterStates;
        return copy;
      });
    }
  }, [detainee.custodyStates]);

  const goToDetainee = (e) => {
    e.preventDefault();
    if (userRoles.includes("4SightAdmin")) {
      navigate(`/Agencies/agency/${agency}/detainee/${detainee.id}`, {
        state: dataInfo,
      });
    } else {
      navigate(`/Detainees/detainee/${detainee.id}`, {
        state: dataInfo,
      });
    }
  };

  return (
    <tr className="table-pointer" onClick={goToDetainee}>
      <td>{localDateString}</td>
      <td>{description}</td>
      <td>
        {critical.states.length > 0 ? (
          <Badge pill bg="danger">
            Has Critical Status
          </Badge>
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
}

export default DetaineeTableData;
