import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepickercomponent.css";

const DatePickerComponent = React.memo(
  ({
    selectedDate,
    handleDateChange,
    filteredDates,
    filteredTimes,
    choosingDisabled,
    noTypingAllowed,
  }) => {
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="form-control"
        value={value}
        onClick={onClick}
        ref={ref}
        readOnly // Disables typing
      />
    ));
    return (
      <DatePicker
        selected={selectedDate}
        includeTimes={filteredTimes}
        onChange={handleDateChange}
        showTimeSelect
        includeDates={filteredDates}
        timeIntervals={60}
        customInput={noTypingAllowed && <CustomInput />}
        disabled={choosingDisabled}
        dateFormat="yyyy-MM-dd h:mm:ss a"
        className="form-control datepicker-input"
      />
    );
  }
);

export default DatePickerComponent;
