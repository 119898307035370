import React, { useCallback, useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import azureFunctionService from "../Services/azureFunctionService";
import AgencyTab from "./AgencyTab";

function Agencies({ userMetaData, user }) {
  const [agencies, setAgencies] = useState({ main: [], components: [] });
  const [search, setSearch] = useState({
    keyWord: "",
  });
  const searchInputRef = useRef(null);
  const mappingAgencyInfo = useCallback(
    (agency) => {
      return (
        <AgencyTab
          agency={agency}
          user={userMetaData}
          key={`Agency-${agency.id}`}
        />
      );
    },
    [userMetaData]
  );
  useEffect(() => {
    if (user?.userroles?.includes("4SightAdmin") && search.keyWord !== "") {
      const payload = {
        pageNumber: "0",
        search: search?.keyWord,
      };
      azureFunctionService
        .getAgenciesBySearch(payload, userMetaData?.admToken)
        .then((response) => {
          setAgencies((prevState) => {
            const copy = { ...prevState };
            copy.components = response.data.detainees.map(mappingAgencyInfo);
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (user?.userroles?.includes("4SightAdmin")) {
      const payload = { pageNumber: "0" };
      azureFunctionService
        .getAllAgencies(payload, userMetaData?.admToken)
        .then((response) => {
          setAgencies((prevState) => {
            const copy = { ...prevState };
            copy.main = response.data;
            copy.components = response.data.detainees.map(mappingAgencyInfo); //mapp this components
            return copy;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    mappingAgencyInfo,
    search.keyWord,
    user?.userroles,
    userMetaData?.admToken,
  ]);

  const onReset = () => {
    setSearch({ keyWord: "" });
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    setAgencies((prevState) => {
      const copy = { ...prevState };
      copy.components = agencies.main.detainees.map(mappingAgencyInfo);
      return copy;
    });
  };

  const onChangeSearchField = (event) => {
    const target = event.target;
    const newValue = target.value;
    const fieldName = target.name;

    setSearch((prevState) => {
      const copy = { ...prevState };
      copy.keyWord = newValue;
      return copy;
    });
    console.log({ fieldName, newValue });
  };

  return (
    <Container fluid className="my-3">
      <Row>
        <Col lg={12} md={12} sm={12} className="w-100">
          <Card>
            <Card.Header className="cardheaderbg">
              <Row className="align-items-center">
                <Col xs={6} sm={6} md={7} lg={9}>
                  <h2 className="mb-0 text-light">Agencies</h2>
                </Col>
                <Col xs={6} sm={6} md={5} lg={3} className="text-right">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button
                        className="btn btn-outline-secondary mx-2 reset-btn"
                        type="button"
                        onClick={onReset}
                      >
                        Reset
                      </button>
                    </div>
                    <input
                      type="text"
                      className="form-control rounded"
                      id="search"
                      value={search.keyWord}
                      onChange={onChangeSearchField}
                      name="search"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="search-addon"
                      ref={searchInputRef}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive border-0 overflow-y-hidden">
                <Table hover>
                  <thead className="lightheader-room">
                    <tr>
                      <th>
                        <strong>Name</strong>
                      </th>
                      <th>
                        <strong>Domain</strong>
                      </th>
                      <th>
                        <strong>Type</strong>
                      </th>
                      <th>
                        <strong>Abbrevation</strong>
                      </th>
                      <th>
                        <strong>Date</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{agencies.components}</tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Agencies;
