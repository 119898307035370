import axios from "axios";

const getAgency = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getAgencySearch = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getAgencyDate = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getAgencyCritical = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getAgencyDateSearch = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getAgencyCriticalDate = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getAgencyCriticalSearch = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getAgencyCriticalDateSearch = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

// COUNTING

const getAgencyCount = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgencySearchCount = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgencyDateCount = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgencyCriticalCount = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgencyDateSearchCount = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgencyCriticalDateCount = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgencyCriticalSearchCount = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgencyCriticalDateSearchCount = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getCustody = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
    responseType: "arraybuffer",
  };
  return axios(config);
};

const getCustodyFirstRender = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getCustodyHourData = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://custodyreportstrigger.azurewebsites.net/api/custodyReportsTrigger",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
    responseType: "arraybuffer",
  };
  return axios(config);
};
const getAllAgencies = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://cpreportsjstrigger.azurewebsites.net/api/GetAllAgencies",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};
const getAgenciesBySearch = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://cpreportsjstrigger.azurewebsites.net/api/GetAgencyBySearch",
    data: payload,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const getDetaineesJmsData = (payload, token) => {
  const config = {
    method: "POST",
    url: "https://cpreportsjstrigger.azurewebsites.net/api/GetDetaineesJmsData",
    withCredentials: true,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "x-functions-key": token,
    },
  };
  return axios(config);
};

const azureFunctionService = {
  getDetaineesJmsData,
  getAgenciesBySearch,
  getAllAgencies,
  getCustodyHourData,
  getCustodyFirstRender,
  getCustody,
  getAgency,
  getAgencySearch,
  getAgencyDate,
  getAgencyCritical,
  getAgencyDateSearch,
  getAgencyCriticalDate,
  getAgencyCriticalSearch,
  getAgencyCriticalDateSearch,
  getAgencyCount,
  getAgencyDateCount,
  getAgencySearchCount,
  getAgencyCriticalCount,
  getAgencyDateSearchCount,
  getAgencyCriticalDateCount,
  getAgencyCriticalSearchCount,
  getAgencyCriticalDateSearchCount,
};

export default azureFunctionService;
