import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="4sightlabs.us.auth0.com"
      clientId="HbQJYxD866lki6fLwlS9icfBPtMD74xC"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://4sightlabs.us.auth0.com/api/v2/",
        scope: "read:current_user update:current_user_metadata profile email read:roles update:roles"
      }}
    >

      <BrowserRouter>
        <App />
      </BrowserRouter>

    </Auth0Provider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
