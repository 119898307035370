import React from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import Login from "./Login";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Power, PersonCircle } from "react-bootstrap-icons";
import "./navigationbar.css";

function NavigationBar({ isAuthenticated }) {
  const { user, logout } = useAuth0();
  return (
    <Navbar className="bar-color" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src="https://static.wixstatic.com/media/00cb54_0c69252ece4f454893e641acc5faf342~mv2.png"
            width="220"
            height="40"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className="text-light">
              Home
            </Nav.Link>
          </Nav>
          <div className="d-flex align-items-center ms-auto flex-nowrap">
            {isAuthenticated ? (
              <React.Fragment>
                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="d-flex align-items-center text-light"
                    style={{ outline: "none" }}
                  >
                    <div className="mx-2" style={{ fontSize: "18px" }}>
                      {user?.name ? user.name : "Unknown"}
                    </div>
                    {user?.picture ? (
                      <img
                        src={user?.picture}
                        width="50"
                        height="50"
                        className="rounded-circle me-2"
                        alt="Profile"
                      />
                    ) : (
                      <PersonCircle size={50} />
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        logout({
                          logoutParams: {
                            returnTo: window.location.origin,
                          },
                        });
                      }}
                      style={{ width: "100%" }}
                    >
                      <Power size={20} className="mx-2" /> Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </React.Fragment>
            ) : (
              <Login />
            )}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
