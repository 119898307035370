import React from "react";
import moment from "moment";

function TimedTableData({ item }) {
  const value = () => {
    if (item.measurement === "hr" || item.measurement === "bt") {
      return item.hrValue;
    } else if (item.measurement === "es") {
      return item.exertionValue;
    }
  };
  const date = moment.utc(item.timestamp, "YYYY-MM-DD h:mm:ss A");
  const localDate = date.local();
  const localDateString = localDate.format("YYYY-MM-DD h:mm:ss A");

  return (
    <tr className="table-pointer">
      <td>{localDateString}</td>
      <td>{item.measurement}</td>
      <td>{value()}</td>
    </tr>
  );
}

export default TimedTableData;
