import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Landing({ isAuthenticated, user }) {
  const [text, setText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated === true && user?.userroles?.includes("4SightAdmin")) {
      navigate("/Agencies");
    } else if (isAuthenticated === true) {
      navigate("/Detainees");
    }
  }, [isAuthenticated, navigate, user?.userroles]);
  useEffect(() => {
    const phrases = ["Custody Protect Reports"];
    let count = 0;
    let index = 0;
    let currentText = "";
    let letter = "";

    const updateText = () => {
      if (count === phrases.length) {
        count = 0;
      }

      currentText = phrases[count];
      letter = currentText.slice(0, ++index);

      setText(letter);

      if (letter.length === currentText.length) {
        clearInterval(interval);
        setTimeout(() => {
          setText(currentText);
        }, 135); // Change this value to adjust how long the text stays
      }
    };

    const interval = setInterval(updateText, 135);

    return () => clearInterval(interval);
  }, []);

  const styles = {
    backgroundImage: `url('https://static.wixstatic.com/media/00cb54_ec61035f06f0422cb729af3f3c8c7d7a~mv2.jpg/v1/fill/w_1000,h_503,al_c,q_85,enc_auto/00cb54_ec61035f06f0422cb729af3f3c8c7d7a~mv2.jpg')`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: "5rem",
    textAlign: "center",
    backdropFilter: "blur(5px)",
  };
  return <div style={styles}>{text}</div>;
}

export default Landing;
