import React from "react";
import { Col, Card } from "react-bootstrap";
import "./statesnav.css";
import moment from "moment";

function StatesNav({ state, setStartDate, setEndDate }) {
  const unixTimeStamp = state[1].createdAt;
  const date = moment.utc(unixTimeStamp);
  const localDate = date.local();
  const localDateString = localDate.format("YYYY-MM-DD h:mm:ss A");
  const startTime = unixTimeStamp - 30 * 60 * 1000;
  const endTime = unixTimeStamp + 30 * 60 * 1000;
  const setStartEndDate = (e) => {
    e.preventDefault();
    setEndDate(endTime);
    setStartDate(startTime);
  };

  return (
    <Col xs={12} md={12} lg={12}>
      <Card className="border p-2 state-card" onClick={setStartEndDate}>
        <Card.Body>
          <h6>{state[1].message}</h6>
          <h6>{localDateString}</h6>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default StatesNav;
