import React from "react";
import "./welcomemessage.css";
import "animate.css";

function WelcomeMessage({ user }) {
  return (
    <div>
      <h1 className="position-center">
        Welcome, <span className="highlight-dark-blue">{user?.name}!</span>
      </h1>

      <h1 className="position-under center">
        Please email{" "}
        <span className="highlight-dark-blue">admin@4SightLabs.com</span> to see
        your organization's data.
      </h1>
    </div>
  );
}
export default WelcomeMessage;
