import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function AgencyTab({ agency, user }) {
  const [data, setData] = useState({ agency: [], user: [] });
  const navigate = useNavigate();

  const convertDate = moment.utc(agency.createdAt);
  const localDate = convertDate.local();
  const localDateString = localDate.format("YYYY-MM-DD h:mm:ss A");
  const agencyName = agency.name;
  const agencyAbbr = agency.abbreviation;
  const agencyDomain = agency.emailDomain;
  const agencyType = agency.type;

  useEffect(() => {
    setData((prevState) => {
      const copy = { ...prevState };
      copy.agency = agency;
      copy.user = user;
      return copy;
    });
  }, [agency, user]);

  const goToAgency = (e) => {
    e.preventDefault();
    navigate(`/Agencies/agency/${agency.id}`, {
      state: data,
    });
  };

  return (
    <tr className="table-pointer" onClick={goToAgency}>
      <td>{agencyName}</td>
      <td>{agencyDomain}</td>
      <td>{agencyType}</td>
      <td>{agencyAbbr}</td>
      <td>{localDateString}</td>
    </tr>
  );
}
export default AgencyTab;
